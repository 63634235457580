import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import Homepage from '@/router/pages/home'
import SecondPage from '@/router/pages/second-page'
import Categories from '@/router/pages/categories'
import Kassa from '@/router/pages/kassa'
import Analytics from '@/router/pages/analytics'
import Users from '@/router/pages/users'
import Orders from '@/router/pages/orders'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...Homepage,
    ...SecondPage,
    ...Categories,
    ...Kassa,
    ...Users,
    ...Orders,
    ...Analytics,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // to, from, next
  const loggedIn = isUserLoggedIn()
  if (!loggedIn && to.matched.some(record => record.meta.requiresAuth)) {
    next({ name: 'login' })
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
